/* Right Section (Skills) */
.skills-container {
    margin-top: 40px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    text-align: center; /* Center text */
    gap: 20px;
  }
  
  .skills-header {
    margin-bottom: 40px;
    font-size: 2rem; /* Make title bigger */
    font-weight: bold; /* Make title bold */
    color: #ffffff; /* Optional: change text color */
    text-align: center; /* Ensure title is centered */
  }
  
  .icon-cloud {
    width: 100%;
    max-width: 400px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .about-container {
      flex-direction: column;
      align-items: center;
    }
    
  
    .skills-container {
      margin-top: 10px;
      text-align: center;
    }
  }
  
  @media (max-width: 480px) {
    .skills-header {
      font-size: 1.9rem; /* Adjust size for smaller screens */
      text-align: left;
    }
  }
  