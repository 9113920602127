/* General Page Styles */
.testimonials-page {
  padding: 40px 20px;
  text-align: center;
}

/* Section Title */
.section-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 30px;
  color: #ffffff;
}

/* Testimonials Container */
.testimonials-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 cards per row on desktop */
  gap: 20px;
  justify-content: center;
}

/* Testimonial Card */
.testimonial-card {
  background: rgba(255, 255, 255, 0.1); /* Glass morphism background */
  backdrop-filter: blur(15px);
  padding: 15px;
  border-radius: 15px;
  display: flex;
  flex-direction: row; /* Align content horizontally */
  align-items: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
  height: auto; /* Adjust height based on content */
  width: 395px; /* Adjust the width for a smaller card */
  margin: 0 auto; /* Center align the cards */
}

/* Hover effects for the card */
.testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
  background: rgba(255, 255, 255, 0.15); /* Slightly brighten the background */
}

/* Interaction feedback: Scale image on hover */
.testimonial-card:hover .testimonial-image {
  transform: scale(1.1);
  transition: transform 0.3s ease;
  border-color: rgba(255, 255, 255, 0.4); /* Highlight border on hover */
}

/* Interaction feedback: Highlight text */
.testimonial-card:hover .testimonial-name {
  color: #ffffff; /* Highlight name in a different color */
  transition: color 0.3s ease;
}

.testimonial-card:hover .testimonial-text {
  color: #d1d1d1; /* Slightly brighten testimonial text */
  transition: color 0.3s ease;
}

/* Image Section */
.testimonial-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px; /* Space between image and text */
  border: 3px solid rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, border-color 0.3s ease;
}

/* Card Content Section */
.testimonial-content {
  text-align: left;
  color: #ffffff;
}

.testimonial-name {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.testimonial-position {
  font-size: 1rem;
  font-style: italic;
  margin-bottom: 10px;
  color: #b3b3b3;
}

.testimonial-text {
  font-size: 0.95rem;
  color: #ffffff;
  margin-top: 5px;
  line-height: 1.4;
}

/* Responsive Design */

/* Tablets */
@media (max-width: 1024px) {
  .testimonials-container {
      grid-template-columns: repeat(2, 1fr); /* 2 cards per row for tablets */
  }
  .testimonial-card {
      width: 90%; /* Adjust card width to be more flexible for tablet screens */
  }
}

/* Mobile */
@media (max-width: 768px) {
  .testimonials-container {
      grid-template-columns: 1fr; /* 1 card per row for mobile */
  }

  .testimonial-card {
      width: 90%; /* Adjust card width to fit mobile screens */
  }

  .testimonial-card {
      flex-direction: column; /* Stack content vertically for smaller screens */
      align-items: center;
  }

  .testimonial-image {
      margin-right: 0; /* Remove space between image and text on mobile */
      margin-bottom: 15px; /* Add some spacing between image and text */
  }
  .section-title {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 30px;
    color: #ffffff;
    text-align: left;
    margin-left: 20px;
  }
}
