.languages-section {
    padding: 20px;
    margin-top: 40px;
    /* Removed the background-color */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .languages-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .languages-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .language-item {
    font-size: 16px;
    margin: 8px 0;
    font-style: italic;
    color: #4f4f4f; /* Light black color */
  }
  
  .language-name {
    font-weight: bold;
  }
  
  .language-level {
    color: #6f6f6f;
  }
  