/* Music Section */
.music-section {
    padding: 40px 20px;
    font-family: 'Arial', sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .music-title {
    font-size: 2rem;
    color: #ffffff;
    font-weight: bold;
    margin-bottom: 30px;
    text-transform: uppercase;
  }
  
  .recent-release {
    max-width: 950px;
    width: 100%;
    text-align: left;
  }
  
  .recent-release-title {
    font-size: 1.2rem;
    margin-bottom: 0px;
    color: #aeaeae;
    /* font-style: italic; */
  }
  
  /* Music Card */
  .music-card {
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.1); /* Glassmorphism effect */
    border-radius: 20px;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    padding: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    width: 950px;
  }
  
  .music-card:hover {
    transform: scale(1.05);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  }
  
  .music-card-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 15px;
    margin-right: 20px;
  }
  
  .music-card-details {
    flex-grow: 1;
  }
  
  .song-name {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 5px;
    color: #ffffff;
  }
  
  .artist-name {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #cccccc;
    font-style: italic;
  }
  
  .play-button {
    padding: 10px 30px;
    font-size: 1rem;
    color: white;
    border: 2px solid white;
    background: transparent;
    border-radius: 25px;
    cursor: pointer;
    transition: background 0.3s ease, color 0.3s ease;
  }
  
  .play-button:hover {
    background: white;
    color: black;
  }
  
  /* Icons Under Card */
  .music-icons {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 20px;
    font-size: 1rem;
  }
  
  .music-icon {
    color: rgba(255, 255, 255, 0.8);
    transition: color 0.3s ease, transform 0.2s ease;
  }
  
  .music-icon:hover {
    transform: scale(1.2);
  }
  
  .music-icon:nth-child(1):hover {
    color: #1DB954; /* Spotify Brand Color */
  }
  
  .music-icon:nth-child(2):hover {
    color: #FF0000; /* YouTube Brand Color */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .music-card {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .music-card-image {
      margin-right: 0;
      margin-bottom: 15px;
    }
  
    .recent-release-title,
    .music-title {
      font-size: 1.5rem;
    }
  }
  

  /* General Mobile and Tablet Styles */
@media (max-width: 1024px) {
    .music-card {
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 90%; /* Reduce card width */
    }
  
    .music-card-image {
      margin-right: 0;
      margin-bottom: 15px;
      width: 120px; /* Smaller image for mobile/tablet */
      height: 120px;
    }
  
    .song-name {
      font-size: 1.2rem; /* Reduce text size for mobile */
    }
  
    .artist-name {
      font-size: 0.9rem; /* Adjust artist name size */
    }
  
    .play-button {
      padding: 8px 20px; /* Reduce button size */
      font-size: 0.9rem;
    }
  
    .music-icons {
      gap: 20px; /* Smaller gap between icons */
    }
  
    .music-icon {
      font-size: 1rem; /* Adjust icon size */
    }
  }
  
  /* iPad (landscape and portrait) */
  @media (max-width: 768px) {
    .music-title,
    .recent-release-title {
      font-size: 2rem; /* Adjust title size */
    }
  
    .music-card {
      width: 95%; /* Slightly smaller card */
    }
  }
  
  /* Small Mobile Devices (less than 480px) */
  @media (max-width: 480px) {
    .music-title,
    .recent-release-title {
      font-size: 1.5rem; /* Further reduce title size */
    }
  
    .music-card-image {
      width: 100px; /* Smaller image */
      height: 100px;
    }
  
    .song-name {
      font-size: 1rem; /* Adjust song name size */
    }
  
    .artist-name {
      font-size: 0.8rem; /* Adjust artist name size */
    }
  
    .play-button {
      padding: 6px 18px; /* Smaller button */
      font-size: 0.8rem;
    }
  
    .music-icons {
      gap: 15px; /* Narrow gap for icons */
    }
  
    .music-icon {
      font-size: 1rem; /* Further reduce icon size */
    }
    
  }
  