/* Add this to your global CSS file, e.g., index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  background-color: #000000;
  color: #fff;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
