/* Projects Section */
.projects-portfolio {
  padding: 40px 20px;
  text-align: center;
}

.portfolio-title {
  font-size: 2rem;
  color: white;
  font-weight: bold;
  margin-bottom: 40px;

}

.project-cards-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Default: 3 cards per row on desktop */
  gap: 30px;
  justify-content: center; /* Center the grid content */
  margin: 0 auto; /* Ensure grid is centered */
}

/* Card Styles */
.project-card {
  background: rgba(255, 255, 255, 0.1); /* Glassmorphism background */
  backdrop-filter: blur(15px);
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 270px; /* Fixed width for cards */
  height: 500px; /* Fixed height for cards */
  max-width: 270px; /* Limit the max width of the card */
  min-height: 500px; /* Ensure height is always 500px */
  margin: 0 auto; /* Center each card in the grid */
}

.project-card:hover {
  transform: translateY(-10px);
}

/* Image Section */
.card-image {
  width: 250px; /* Fixed width for the image */
  height: 250px; /* Fixed height for the image */
  border-radius: 15px; /* Rounded corners */
  overflow: hidden;
  margin-bottom: 10px;
  flex-shrink: 0; /* Prevent the image from shrinking */
}

.card-image img {
  width: 100%; /* Ensure the image covers the full width of the container */
  height: 100%; /* Ensure the image covers the full height of the container */
  object-fit: cover; /* This ensures the image maintains its aspect ratio */
}

/* Card Content Section */
.card-content {
  color: white;
  text-align: center;
  flex-grow: 1;
}

.card-content h3 {
  font-size: 1.5rem;
  font-weight: 600; /* Slightly bolder than regular */
  margin-bottom: 10px;
}

.card-content p {
  font-size: 1rem;
  margin-bottom: 15px;
  max-height: 100px; /* Limit description height */
  overflow: hidden; /* Prevent text overflow */
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
  font-style: italic; /* Italicize description */
  color: #555; /* Light black color for description */
}

.links-container {
  display: flex;
  justify-content: center;
  gap: 15px;
}

/* Icon Styles */
.link-icon {
  font-size: 1.5rem;
  color: #555; /* Light black color by default */
  transition: color 0.3s ease;
}

.link-icon:hover {
  color: white; /* Turn white on hover */
}

/* Responsive Design */

/* For Tablets */
@media (max-width: 1024px) {
  .project-cards-container {
    grid-template-columns: repeat(2, 1fr); /* 2 cards per row */
    gap: 20px; /* Smaller gap */
  }

  .card-image {
    width: 120px;
    height: 120px; /* Adjust size for smaller screens */
  }
}

/* For Mobile (landscape and portrait) */
@media (max-width: 768px) {
  .project-cards-container {
    grid-template-columns: 1fr; /* 1 card per row */
    gap: 15px; /* Smaller gap */
    padding: 0 10px; /* Add padding on mobile */
  }

  .project-card {
    width: 90%; /* Card takes up more width on mobile */
    max-width: 100%; /* Allow card to be 100% of screen width */
  }

  .card-image {
    width: 160px;
    height: 160px; /* Adjust size */
  }

  .card-content h3 {
    font-size: 1.3rem; /* Reduce title size */
  }

  .card-content p {
    font-size: 0.9rem; /* Reduce description size */
    max-height: none; /* Remove max height restriction */
  }
}

/* For Very Small Mobile Screens */
@media (max-width: 480px) {
  .project-cards-container {
    grid-template-columns: 1fr; /* 1 card per row */
    padding: 0 20px; /* Padding on very small screens */
  }

  .card-image {
    width: 280px;
    height: 260px; /* Adjust size */
  }

  .card-content h3 {
    font-size: 1.2rem; /* Reduce title size */
  }

  .card-content p {
    font-size: 0.8rem; /* Reduce description size */
  }

  .links-container {
    gap: 10px; /* Reduce icon spacing */
  }

  .link-icon {
    font-size: 1.2rem; /* Adjust icon size */
  }
  .portfolio-title {
    font-size: 1.6rem;
    color: white;
    font-weight: bold;
    margin-bottom: 40px;
    text-align: left;
    margin-left: 20px;
  
  }
}


