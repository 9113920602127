/* src/components/Navigation.css */

/* Importing the Don Graffiti font from local storage */
@font-face {
    font-family: 'Don Graffiti';
    src: url('../assets/fonts/DonGraffiti.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

.nav {
    width: 100%; /* Set the nav to take full width */
    background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent white for glass effect */
    backdrop-filter: blur(10px); /* Glass effect */
    padding: 10px 20px; /* Add some padding */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
    display: flex;
    justify-content: space-between; /* Space between logo and menu items */
    align-items: center;
    position: fixed; /* Keep fixed on top */
    top: 0; /* Align to the top */
    left: 0; /* Align to the left */
    z-index: 1000; /* Ensure it's above other content */
}

/* Logo Styles */
.logo {
    font-family: 'Don Graffiti', cursive; /* Apply your custom font */
    color: white; /* Logo color */
    font-size: 24px; /* Adjust logo size */
}

/* Hamburger Menu Styles */
.hamburger {
    display: none; /* Hide on larger screens */
    flex-direction: column;
    cursor: pointer;
    z-index: 1001; /* Ensure it’s above other content */
}

/* Bar styles for the hamburger menu */
.bar {
    height: 4px;
    width: 25px;
    background-color: white;
    margin: 4px 0;
    transition: 0.4s; /* Smooth transition */
}

/* Nav List Styles */
.nav-list {
    display: flex; /* Keep it visible on larger screens */
    list-style: none;
    padding: 0;
    margin: 0;
}

/* Active styles */
.nav-item {
    margin-left: 20px; /* Space between items */
}

/* Responsive styles */
@media (max-width: 768px) { /* Tablet and below */
    .hamburger {
        display: flex; /* Show hamburger on mobile */
    }

    .nav-list {
        position: fixed; /* Fixed position */
        top: 0; /* Start from the top */
        right: 0; /* Align to the right */
        background-color: rgba(0, 0, 0, 0.8); /* Dark background for the menu */
        width: 70%; /* Adjust width as needed */
        height: 100%; /* Full height */
        flex-direction: column; /* Stack items vertically */
        align-items: flex-end; /* Align items to the right */
        display: none; /* Hidden by default */
        z-index: 1000; /* Ensure it's above other content */
        transition: transform 0.3s ease; /* Smooth transition */
        transform: translateX(100%); /* Off-screen initially */
    }

    .nav-list.active {
        display: flex; /* Show on menu open */
        transform: translateX(0); /* Bring on screen */
    }

    .nav-item {
        margin: 20px 0; /* Add spacing between items */
        opacity: 0; /* Start invisible */
        animation: fadeIn 0.3s forwards; /* Fade in effect */
    }

    /* Delay each item by 0.1s */
    .nav-item:nth-child(1) { animation-delay: 0.1s; }
    .nav-item:nth-child(2) { animation-delay: 0.2s; }
    .nav-item:nth-child(3) { animation-delay: 0.3s; }
    .nav-item:nth-child(4) { animation-delay: 0.4s; }
}

/* Animation for Hamburger Menu */
.hamburger.active .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.hamburger.active .bar:nth-child(2) {
    opacity: 0; /* Hide middle bar */
}

.hamburger.active .bar:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
}

/* Active link highlighting */
.nav-link {
    font-family: 'Don Graffiti', cursive; /* Use Don Graffiti font */
    color: rgba(255, 255, 255, 0.7); /* Default color */
    text-decoration: none;
    transition: color 0.3s ease;
    font-size: 20px; /* Increase text size */
}

.nav-link:hover {
    color: white; /* Change color on hover */
}

/* Fade-in animation for menu items */
@keyframes fadeIn {
    from {
        opacity: 0; /* Start invisible */
        transform: translateY(-20px); /* Move up slightly */
    }
    to {
        opacity: 1; /* Fully visible */
        transform: translateY(0); /* Move to original position */
    }
}
