/* Video Section */
.video-section {
    padding: 40px 20px;
    text-align: center;
    font-family: 'Arial', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .video-title {
    font-size: 2rem;
    color: #ffffff;
    font-weight: bold;
    margin-bottom: 30px;
    /* text-transform: uppercase; */
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }
  
  .video-container {
    width: 950px;
    height: 535px; /* Default height, adjustable based on your needs */
    background: rgba(255, 255, 255, 0.1); /* Glassmorphism effect */
    border-radius: 20px;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    backdrop-filter: blur(10px); /* Glassmorphism blur effect */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; /* Ensures absolute positioning works for child elements */
  }
  
  .video-container iframe {
    width: 100%;
    height: 100%;
    border: none; /* Removes border */
    border-radius: 10px;
    object-fit: cover; /* Ensures the video covers the entire container */
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 1024px) {
    .video-container {
      width: 100%;
      height: auto; /* Adjust height based on aspect ratio for smaller screens */
      max-width: 100%; /* Ensures it doesn't overflow */
    }
    
    .video-title {
      font-size: 2rem; /* Adjust title size for tablets */
      text-align: left;
    }
  }
  
  @media (max-width: 768px) {
    .video-title {
      font-size: 1.8rem; /* Smaller font size for mobile */
      margin-bottom: 20px;
      text-align: left;
    }
  
    .video-container {
      width: 100%;
      height: auto; /* Allow height to auto-adjust for different aspect ratios */
      max-width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .video-title {
      font-size: 1.6rem; /* Even smaller font size for smaller screens */
      text-align: left;
    }
  
    .video-container {
      width: 100%;
      height: auto;
      max-width: 100%;
    }
  }
  