/* General Styles */
body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #000;
    color: #fff;
}

/* Hide scrollbar but still allow scrolling */
body, html {
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
  }
  
  body::-webkit-scrollbar {
    display: none;
  }
  

  
/* Hero Section */
.hero {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(3, 3, 3);
    overflow: hidden;
}

.content {
    position: absolute;
    top: -90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: white;
    width: 100%;
    z-index: 2;
}


.location {
    display: inline-flex;
    align-items: center;
    font-size: 1rem;
    margin-top: 5px;
    color: #ffffff; /* Adjust color based on your theme */
  }
  
  .location i {
    margin-right: 8px; /* Adds spacing between the icon and text */
    color: #ffffff; /* Optional: Add a distinct color to the icon */
  }
  
.title2 {
    margin-top: 7%;
    font-family: 'Impact', sans-serif;
    font-size: 180px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
}

.line {
    display: block;
    opacity: 0;
    transform: translateY(100%);
    animation: lineReveal 0.9s forwards ease-out;
}

.title2 .line {
    animation-delay: 0.2s;
}

.modelWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
    overflow: hidden;
}

/* Call to Action Button */
.cta-button-hover {
    margin-top: 20px;
    padding: 12px 30px;
    font-size: 16px;
    font-weight: bold;
    border: 2px solid white;
    background-color: transparent;
    color: white;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.4s ease;
}

.cta-button-hover:hover {
    background-color: white;
    color: black;
}

/* Social Media Icons */
.social-icons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
}

.social-icons a {
    font-size: 24px;
    color: white;
    transition: color 0.3s ease;
    z-index: 2;
}

.social-icons a:hover {
    color: #1877f2; /* Default hover color */
}

.social-icons a.linkedin:hover {
    color: #0077b5; /* LinkedIn brand color */
}

.social-icons a.behance:hover {
    color: #1769ff; /* Behance brand color */
}

.social-icons a.spotify:hover {
    color: #1db954; /* Spotify brand color */
}

.social-icons a.instagram:hover {
    color: #e4405f; /* Instagram gradient pink */
}

.social-icons a.youtube:hover {
    color: #ff0000; /* YouTube red */
}

/* Decorative Image */
.rotated-image {
    position: absolute;
    top: 30%;
    right: -85px;
    transform: rotate(90deg) translateY(-50%);
    max-width: 90%;
    z-index: 1;
    opacity: 0;
    animation: slideIn 1.2s ease-out forwards;

}

/* Scroll Animation */
.scroll {
    position: absolute;
    bottom: 30px;
    right: 20px;
    text-align: center;
    z-index: 5;
    height: 175px;
    overflow: hidden;
}

.scroll__line {
    position: absolute;
    top: -55.9px;
    left: 50%;
    display: block;
    width: 1px;
    height: 50%;
    z-index: 10;
    background-color: #fff;
    animation: scroll 2.4s infinite normal;
    transform: translateY(-50%);
}

.scroll__arrow {
    display: block;
    position: relative;
    margin-left: 1.8px;
    z-index: 111;
    margin-top: 110px;
    fill: #FFFFFF;
    stroke: #FFFFFF;
}

/* Description Section */
.description-section {
    margin-top: 8%;
    text-align: center;
    color: white;
    padding: 0 20px;
    font-family: 'Roboto', sans-serif;
    z-index: 2;
}

.description {
    font-size: 1.2rem;
    line-height: 1.8;
    max-width: 600px;
    margin: 0 auto;
}

/* Keyframes */
@keyframes glowPulse {
    0%, 100% {
        box-shadow: 0 0 8px rgba(255, 255, 255, 0.2);
    }
    50% {
        box-shadow: 0 0 12px rgba(255, 255, 255, 0.3);
    }
}

@keyframes lineReveal {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes scroll {
    0% {
        transform: translate3d(0, -100%, 0);
    }
    100% {
        transform: translate3d(0, 100%, 0);
    }
}

@keyframes slideIn {
    from {
        right: -200px;
        opacity: 0;
    }
    to {
        right: -85px;
        opacity: 1;
    }
}

/* Responsive Styles */
@media (max-width: 768px) {
    .description-section {
        font-size: 1rem;
    }

    .description {
        font-size: 0.9rem;
        margin-top: 60%;
    }

    .rotated-image{
        width: 70%;
        margin-right: 20px;
        margin-top: 35px;
    }
}

@media (max-width: 480px) {
    .description {
        font-size: 0.9rem;
        margin-top: 100%;
    }
    
    .rotated-image{
        width: 70%;
        margin-right: 20px;
        margin-top: 70px;
    }

    .title2 {
        font-size: 6rem;
    }

}


.full-width-footer {
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: #f8f9fa; /* Adjust background color as needed */
    display: block;
  }
  
  .full-width-footer > * {
    max-width: 100%;
  }
  

  