/* Contact Section */
.contact-section {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%; /* Full width of the viewport */
    padding: 0 20px; /* Small padding for mobile */
    box-sizing: border-box;
    flex-direction: column;
  }
  
  /* Glassmorphism Card */
  .contact-card {
    width: 100%; /* Full width for mobile/tablet */
    max-width: 950px; /* Max width for larger screens */
    padding: 40px;
    background: rgba(255, 255, 255, 0.1); /* Transparent white */
    backdrop-filter: blur(15px); /* Blur effect */
    border-radius: 20px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37);
    border: 1px solid rgba(255, 255, 255, 0.18);
    text-align: center;
  }
  
  /* Title */
  .contact-title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #fff;
    text-transform: uppercase;
  }
  
  /* Form */
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-group {
    width: 100%;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 15px;
    font-size: 1rem;
    border: none;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.5);
    transition: background 0.3s ease, box-shadow 0.3s ease;
  }
  
  .form-group input::placeholder,
  .form-group textarea::placeholder {
    color: #bbb;
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    outline: none;
    background: #333(255, 255, 0, 0.3);
    box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.5);
  }
  
  /* Button */
  .submit-button {
    padding: 15px;
    font-size: 1rem;
    color: #fff;
    background: transparent;
    border: 2px solid #fff;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .submit-button:hover {
    background: #fff;
    color: #000;
    transform: scale(1.05);
  }
  
  /* Form Status */
  .form-status {
    margin-top: 20px;
    font-size: 1rem;
    color: #28a745; /* Success color */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .contact-card {
      padding: 20px;
      width: 100%; /* Ensure full width on tablets */
    }
  
    .contact-title {
      font-size: 1.8rem;
    }
  
    .form-group input,
    .form-group textarea {
      font-size: 0.9rem;
    }
  
    .submit-button {
      padding: 12px;
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .contact-card {
      padding: 15px;
      width: 100%; /* Ensure full width on mobile */
    }
  
    .contact-title {
      font-size: 1.6rem;
    }
  
    .form-group input,
    .form-group textarea {
      padding: 12px;
      font-size: 0.9rem;
    }
  
    .submit-button {
      padding: 10px;
      font-size: 0.8rem;
    }
  }
  