/* Freelance Projects Section */
.freelance-projects-section {
    padding: 40px 20px;
    text-align: center;
  }
  
  .freelance-projects-title {
    font-size: 2rem;
    color: #ffffff;
    font-weight: bold;
    margin-bottom: 40px;
    font-family: 'Arial', sans-serif;
  }
  
  .freelance-projects-container {
    display: flex;
    gap: 30px; /* Space between cards */
    justify-content: center; /* Center cards horizontally */
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
  }
  
  /* Card Styles */
  .freelance-project-card {
    width: 250px;
    height: 250px; /* Square card */
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .freelance-project-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  }
  
  /* Image Section */
  .freelance-card-image-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .freelance-card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Overlay for Hover Effect */
  .freelance-card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .freelance-card-image-wrapper:hover .freelance-card-overlay {
    opacity: 1; /* Show overlay on hover */
  }
  
  .freelance-card-overlay-content {
    text-align: center;
    color: white;
  }
  
  .freelance-card-overlay-content h3 {
    font-size: 1.3rem;
    margin-bottom: 10px;
    font-weight: 600;
  }
  
  .freelance-card-overlay-content p {
    font-size: 1rem;
    margin-bottom: 15px;
    color: rgba(255, 255, 255, 0.8);
  }
  
  .freelance-link-icon {
    font-size: 1.5rem;
    color: rgba(255, 255, 255, 0.37);
    transition: color 0.3s ease;
  }
  
  .freelance-link-icon:hover {
    color: #ffffff;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .freelance-projects-container {
      gap: 20px;
      justify-content: center;
    }
    .freelance-project-card {
      width: 90%; /* Adapt to smaller screens */
      height: auto; /* Maintain aspect ratio */
    }

    .freelance-projects-title {
      font-size: 1.6rem;
      color: #ffffff;
      font-weight: bold;
      margin-bottom: 40px;
      font-family: 'Arial', sans-serif;
      text-align: left;
      margin-left: 20px;
    }
  }
  