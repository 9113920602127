/* Experience Section */
.experience-section {
    padding: 60px 20px;
    font-family: 'Arial', sans-serif;
    background: transparent;
  }
  
  /* Title */
  .experience-title {
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 40px;
    text-transform: uppercase;
    font-weight: bold;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }
  
  /* Timeline */
  .timeline {
    position: relative;
    max-width: 950px;
    margin: 0 auto;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  /* Single Vertical Line */
  .timeline::before {
    content: "";
    position: absolute;
    top: 0;
    left: 5%;
    width: 3px;
    height: 100%;
    background: #d3d3d3; /* Light grey line */
    transform: translateX(-50%);
  }
  
  /* Timeline Items */
  .timeline-item {
    display: flex;
    align-items: center;
    margin: 20px 0;
    position: relative;
    width: 100%;
    flex-direction: row;
    color: #d3d3d3; /* Light grey text */
  }
  
  /* Hover Effect - Change color of icon, text, and line */
  .timeline-item:hover .timeline-icon {
    background-color: white; /* Icon changes to white */
    color: black; /* Icon color changes to black */
  }
  
  .timeline-item:hover .timeline-content {
    color: white; /* Text changes to white */
  }
  
  .timeline-item:hover::after {
    background-color: white; /* Dots along the line change to white */
  }
  
  /* Timeline Dot (Positioned on the Line) */
  .timeline-item::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 5%;
    width: 12px;
    height: 12px;
    background-color: #d3d3d3; /* Light grey dot */
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
  
  /* Timeline Content */
  .timeline-content {
    text-align: left;
    color: #d3d3d3; /* Light grey text */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 30px; /* Shift the experience content slightly to the right */
  }
  
  /* Timeline Content Title */
  .timeline-content h4 {
    font-size: 1rem;
    font-weight: bold;
  }
  
  /* Timeline Content Description */
  .timeline-content p {
    font-size: 1rem;
    margin-top: 5px;
    font-style: italic;
  }
  
  /* Timeline Icon */
  .timeline-icon {
    width: 40px;
    height: 40px;
    border: 3px solid #d3d3d3; /* Light grey border */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: #d3d3d3; /* Light grey icon */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in-out; /* Smooth transition on hover */
    margin-left: 60px;
  }
  
  /* Timeline Image (Appears on Hover) */
  .timeline-image {
    display: none; /* Hidden by default */
    margin-top: 10px;
    width: 50px; /* Small size */
    height: 50px; /* Square size */
    overflow: hidden;
  }
  
  .timeline-item:hover .timeline-image {
    display: block;
    animation: fadeIn 0.3s ease-out; /* Add animation for smooth display */
  }
  
  .timeline-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px; /* Optional: rounded corners */
  }
  
  /* Smooth Fade In Animation */
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .experience-title {
      font-size: 2rem;
    }
  
    .timeline {
      width: 100%;
      padding: 20px 10px; /* Add padding for smaller screens */
    }
  
    /* Timeline Item layout changes to column for smaller screens */
    .timeline-item {
      flex-direction: row; /* Keep the items in a row (left-aligned) */
      align-items: center;
      margin: 25px 0; /* Reduce margin between items */
    }
  
    /* Move timeline icon to the left */
    .timeline-icon {
      margin-left: 30px;
      margin-right: 20px; /* Add margin on the right side for spacing */
    }
  
    /* Position the dot at the center for mobile */
    .timeline-item::after {
      margin-left: -9px;
    }
  
    /* Adjust the content area to left-align */
    .timeline-content {
      margin-left: 0;
      margin-top: 10px;
      text-align: left;
    }
  
    /* Adjust the image size for smaller screens */
    .timeline-image {
      width: 40px;
      height: 40px;
    }
  }
  
  @media (max-width: 480px) {
    .experience-title {
      font-size: 1.6rem;
    }
  
    .timeline {
      padding: 15px 10px; /* Add some padding for very small screens */
    }
  
    /* For the smallest screens, make the content and icon stack */
    .timeline-item {
      flex-direction: row; /* Keep the items in a row (left-aligned) */
      align-items: center;
    }
  
    /* Center the dots */
    .timeline-item::after {
      left: 5%;
      transform: translateX(-50%);
    }
  
    /* Adjust the image size even further */
    .timeline-image {
      width: 35px;
      height: 35px;
    }
  }
  